import { useQueryParam, Modal } from '@maestro/components';
import { SeriesRpgConfiguration } from './properties/rpg/SeriesRpgConfiguration';

export const PropertiesModal = () => {
  const [paramValue, setParam] = useQueryParam({ param: 'prop' });
  const onClose = () => setParam(undefined, true);

  return (
    <Modal
      isOpen={paramValue === 'open'}
      onClose={onClose}
      title="Properties"
      borderDisabled
    >
      <SeriesRpgConfiguration />
    </Modal>
  );
};
