import { Button, Tooltip } from '@chakra-ui/react';
import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Icon } from '@maestro/components/Icon';
import { useFeatureFlagEvaluation } from '@maestro/feature-flags';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { useRef, DragEvent, MouseEvent } from 'react';
import { OnNodesChange, useReactFlow } from 'reactflow';
import styled from 'styled-components';
import { useStudioFlowStore } from '../hooks/useStudioFlowStore';
import { nodeConfigList } from '../nodes';
import { GhostNode } from '../nodes/GhostNode';
import { NodeConfig, NodeGroup } from '../nodes/Node.types';
import { useUserPreferencesStore } from '../state/userPreferences';

type Props = {
  episodeRef: string | null;
  canUndo: boolean;
  canRedo: boolean;
  onNodesChange: OnNodesChange;
  undo: () => void;
  redo: () => void;
};

export const Sidebar: React.FC<Props> = ({
  episodeRef,
  canUndo,
  canRedo,
  onNodesChange,
  undo,
  redo,
}) => {
  const dragImageRef = useRef<HTMLDivElement>(null);
  const flowInstance = useReactFlow();
  const selectNode = useStudioFlowStore((state) => state.selectNode);
  const { getFlagEvaluation } = useFeatureFlagEvaluation();
  const isAdvancedModeInSidebar = getFlagEvaluation('ENI-881');
  const showUndoRedo = getFlagEvaluation('ENI-911');
  const { isRpgModeEnabled, toggleRpgMode } = useUserPreferencesStore(
    ({ isRpgModeEnabled, toggleRpgMode }) => ({
      isRpgModeEnabled,
      toggleRpgMode,
    }),
  );

  const onDragStart = (
    event: DragEvent<HTMLButtonElement>,
    config: NodeConfig<StudioNodeType, StudioNodeData>,
  ) => {
    if (event.dataTransfer) {
      event.dataTransfer.setData('application/reactflow', config.type);

      if (dragImageRef.current) {
        event.dataTransfer.setDragImage(dragImageRef.current, 0, 0);
      }
    }
  };

  const onClick = (
    event: MouseEvent<HTMLButtonElement>,
    config: NodeConfig<StudioNodeType, StudioNodeData>,
  ) => {
    const { clientX: x, clientY: y } = event;
    const data = config.createNodeData();
    const item = {
      id: data.id,
      type: data.type,
      selected: false,
      position: flowInstance.screenToFlowPosition({ x: x + 20, y: y - 15 }),
      data,
    };

    onNodesChange([{ type: 'add', item }]);
    setTimeout(() => selectNode(data.id), 100);
  };

  const filteredNodeList = nodeConfigList.filter(
    (node) =>
      (node.group !== NodeGroup.Advanced || isRpgModeEnabled) &&
      (!node.episodeRef || node.episodeRef === episodeRef) &&
      (!node.featureFlag || getFlagEvaluation(node.featureFlag)),
  );

  return (
    <OuterContainer>
      <StyledGhostNode ref={dragImageRef} />
      <Container>
        {filteredNodeList.map((config) => (
          <Tooltip key={config.type} label={config.name} placement="right">
            <Button
              draggable
              onClick={(event) => onClick(event, config)}
              onDragStart={(event) => onDragStart(event, config)}
              variant="sidebar"
            >
              <Icon name={config.icon} size={rawDimensions.size16} />
            </Button>
          </Tooltip>
        ))}
        {isAdvancedModeInSidebar && (
          <Tooltip
            closeOnPointerDown
            label={`${isRpgModeEnabled ? 'Hide' : 'Show'} advanced`}
            placement="right"
          >
            <Button
              onClick={() => toggleRpgMode(!isRpgModeEnabled)}
              variant="sidebar"
            >
              <AdvancedIcon
                name={isRpgModeEnabled ? 'chevron-up' : 'chevron-down'}
                size={rawDimensions.size16}
                $open={isRpgModeEnabled}
              />
            </Button>
          </Tooltip>
        )}
      </Container>
      {showUndoRedo && (
        <UndoContainer>
          <Tooltip label="Undo" placement="right">
            <Button
              onClick={() => undo()}
              variant="sidebar"
              isDisabled={!canUndo}
            >
              <Icon name="arrow-hook-up-left" size={rawDimensions.size20} />
            </Button>
          </Tooltip>
          <Tooltip label="Redo" placement="right">
            <Button
              onClick={() => redo()}
              variant="sidebar"
              isDisabled={!canRedo}
            >
              <Icon name="arrow-hook-down-right" size={rawDimensions.size20} />
            </Button>
          </Tooltip>
        </UndoContainer>
      )}
    </OuterContainer>
  );
};

const StyledGhostNode = styled(GhostNode)`
  position: absolute;
  top: -10000px;
  left: -10000px;
`;

const OuterContainer = styled.div`
  position: absolute;
  top: 25%;
  left: ${dimensions.size16};
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  padding: ${dimensions.size4};
  margin-top: -25%;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};
`;

const AdvancedIcon = styled(Icon)<{ $open: boolean }>`
  background-color: ${({ theme, $open }) =>
    $open ? theme.colors.background.shade : theme.colors.background.accent};
  padding: ${dimensions.size2};
  height: unset;
  width: unset;
  border-radius: ${dimensions.size4};
`;

const UndoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  padding: ${dimensions.size4};
  margin-top: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};
`;
