import { EpisodeSetupNodeData } from '@common/studio-types';
import { dimensions } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps } from 'reactflow';
import styled from 'styled-components';
import { BaseStudioNode } from '../BaseStudioNode';
import { EpisodeSetupPropertyItem } from './EpisodeSetupPropertyItem';

type Props = NodeProps<EpisodeSetupNodeData>;

const Node: React.FC<Props> = ({ data, selected }) => {
  return (
    <BaseStudioNode
      nodeId={data.id}
      title={'Episode setup'}
      selected={selected}
    >
      <EpisodeSetupNodeContainer>
        {data.properties.map((property) => (
          <EpisodeSetupPropertyItem key={property.name} data={property} />
        ))}
      </EpisodeSetupNodeContainer>
    </BaseStudioNode>
  );
};

export const EpisodeSetupNode = memo(Node);

const EpisodeSetupNodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;
