import {
  RpgConfig,
  RpgConfigHudElement,
  RpgConfigProperty,
} from '@common/studio-types';
import { Tab, TabBar } from '@maestro/components';
import { useUpdateRpgConfigMutation } from '@maestro/graphql';
import { dimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from '../../../hooks/useDebounce';
import { useItems } from '../../../hooks/useItems';
import { useStudioFlowStore } from '../../../hooks/useStudioFlowStore';
import { HudConfiguration } from './HudConfiguration';
import { PropertiesForm } from './PropertiesForm';

export const SeriesRpgConfiguration: React.FC = () => {
  const { rpgConfig, seriesId, setRpgConfig } = useStudioFlowStore(
    ({ rpgConfig, seriesId, setRpgConfig }) => ({
      rpgConfig,
      seriesId,
      setRpgConfig,
    }),
  );
  const { mutate } = useUpdateRpgConfigMutation();
  const [currentTab, setCurrentTab] = useState('seriesProperties');
  const [properties, setProperties] = useState<RpgConfigProperty[]>(
    rpgConfig.properties ?? [],
  );
  const [hudElements, setHudElements] = useState<RpgConfigHudElement[]>(
    rpgConfig.hud ?? [],
  );
  const { items, isLoading, refetch } = useItems({ seriesId });

  const onSave = useDebounce(
    (changes: Partial<RpgConfig>) => {
      if (rpgConfig && seriesId) {
        const newConfig: RpgConfig = { ...rpgConfig, ...changes };
        mutate({ input: { seriesId, rpgConfig: newConfig } });
        setRpgConfig(newConfig);
      }
    },
    [],
    500,
  );

  useEffect(() => {
    if (rpgConfig) {
      setProperties(rpgConfig.properties);
      setHudElements(rpgConfig.hud);
    }
  }, [rpgConfig]);

  const onHudElementsChange = (elements: RpgConfigHudElement[]) => {
    setHudElements(elements);
    onSave({ hud: elements });
  };

  const tabs = [
    { label: 'Series', value: 'seriesProperties' },
    { label: 'Episode', value: 'episodeProperties' },
    { label: 'Player menu', value: 'hud' },
  ];

  const onSeriesPropertiesChange = (newProperties: RpgConfigProperty[]) => {
    setProperties(newProperties);
    onSave({ properties: newProperties });
  };

  return (
    <Container>
      <TabContainer>
        <TabBar>
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              isActive={currentTab === tab.value}
              onClick={() => setCurrentTab(tab.value)}
            >
              {tab.label}
            </Tab>
          ))}
        </TabBar>
      </TabContainer>
      {currentTab === 'seriesProperties' && (
        <PropertiesForm
          rpgConfig={rpgConfig}
          properties={properties}
          onChange={onSeriesPropertiesChange}
        />
      )}
      {currentTab === 'episodeProperties' && (
        <PropertiesForm
          episodeScopedOnly
          rpgConfig={rpgConfig}
          onChange={() => {
            // TODO ENI-942 - implement episode specific properties
          }}
          properties={[]}
        />
      )}
      {currentTab === 'hud' && (
        <HudConfiguration
          hudElements={hudElements}
          properties={properties}
          onHudElementsChange={onHudElementsChange}
          refetchItems={refetch}
          isLoading={isLoading}
          items={items}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  flex: 1;
`;

const TabContainer = styled.div`
  border-bottom: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
`;
