import { Button, Tooltip } from '@chakra-ui/react';
import { useQueryParam } from '@maestro/components';
import { Icon } from '@maestro/components/Icon';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { memo } from 'react';
import { useReactFlow } from 'reactflow';
import styled from 'styled-components';
import { useAutoLayout } from '../hooks/useAutoLayout';
import { StudioAiAssistant } from './StudioAiAssistant';

const options = { duration: 300 };

type Props = {
  onBeforeChange: VoidFunction;
  onChange: VoidFunction;
};

const Component: React.FC<Props> = (props) => {
  const { zoomIn, zoomOut, fitView, setNodes, setEdges, getNodes, getEdges } =
    useReactFlow();
  const [, setOpen] = useQueryParam({ param: 'prop' });
  const autoLayout = useAutoLayout();

  const organizeNodes = async () => {
    props.onBeforeChange();

    const { nodes, edges } = autoLayout(getNodes(), getEdges());

    setNodes(nodes);
    setEdges(edges);

    setTimeout(() => fitView(options), 100);

    props.onChange();
  };

  const isPropertiesMenuEnabled = useFeatureFlag(FeatureFlags.PropertiesMenu);

  return (
    <>
      <StudioAiAssistant
        onChange={props.onChange}
        onBeforeChange={props.onBeforeChange}
      />
      {isPropertiesMenuEnabled && (
        <LeftContainer>
          <Tooltip label="Properties configuration" placement="top">
            <Button
              onClick={() => setOpen('open')}
              variant="primary"
              size="sm"
              fontSize={dimensions.size12}
              lineHeight="14px"
              borderRadius={dimensions.size8}
              leftIcon={
                <Icon name="property-value" size={rawDimensions.size16} />
              }
            >
              Properties
            </Button>
          </Tooltip>
        </LeftContainer>
      )}
      <RightContainer>
        <ZoomContainer>
          <Tooltip label="Zoom out" placement="top">
            <Button onClick={() => zoomOut(options)} variant="icon">
              <Icon name="minus" size={rawDimensions.size16} />
            </Button>
          </Tooltip>

          <Tooltip label="Zoom in" placement="top">
            <Button onClick={() => zoomIn(options)} variant="icon">
              <Icon name="plus" size={rawDimensions.size16} />
            </Button>
          </Tooltip>
        </ZoomContainer>

        <Tooltip label="Expand to fit view" placement="top">
          <Button onClick={() => fitView(options)} variant="icon">
            <Icon name="expand" size={rawDimensions.size16} />
          </Button>
        </Tooltip>

        <Tooltip label="Organize nodes" placement="top-end">
          <Button onClick={organizeNodes} variant="icon">
            <Icon name="organize-nodes" size={rawDimensions.size16} />
          </Button>
        </Tooltip>
      </RightContainer>
    </>
  );
};

export const StudioControls = memo(Component);

const RightContainer = styled.div`
  position: fixed;
  bottom: ${dimensions.size16};
  right: ${dimensions.size16};
  z-index: 10;
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size6};
`;

const LeftContainer = styled.div`
  position: fixed;
  bottom: ${dimensions.size16};
  left: ${dimensions.size16};
  z-index: 10;
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size6};
`;

const ZoomContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};
`;
